import axios from 'axios';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONTEUDO_API_URL } from '../../constants/api';
import regions from '../../constants/regions';
import { CurrentUserContext, FirebaseContext } from '../../Contexts';
import cities from './cidades.json';
import MapaSvg from './mapa-svg';
import {
  AssistirPrograma,
  BotaoExpandir,
  Botoes,
  BotoesConteudoExpandido,
  Cidade,
  Cidades,
  ConfiraMateria,
  ConfiraNoMapa,
  Container,
  ConteudoExpandido,
  Filtros,
  Formulario,
  IndiqueUmaCidade,
  Mapa,
  Pesquisar,
  Pin,
  Regiao,
  Subtitulo,
  Titulo,
  UltimaExibicao
} from './styles';

const configToast = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}

const MapaPlug = () => {
  const { firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [selectedRegion, setSelectedRegion] = useState('');
  const [search, setSearch] = useState('')
  const [cityOpen, setCityOpen] = useState(null);
  const [showIndicateCity, setShowIndicateCity] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    cidade: '',
    descricao: '',
  });

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (form.cidade && form.descricao) {
      setIsLoading(true)

      const url = `${CONTEUDO_API_URL}/interatividade/mapa-plug`

      const { idSalesforceTelespectador } = currentUser
      const data = {
        account: idSalesforceTelespectador,
        city: `${form.cidade} PR`,
        description: form.descricao,
      }

      const token = await firebaseAuth.currentUser.getIdToken()
      const config = {
        headers: { 'Authorization': `Bearer ${token}` }
      }

      try {
        await axios.post(url, data, config);

        setForm({
          cidade: '',
          descricao: '',
        })

        toast.success('Cidade indicada com sucesso, obrigado!', configToast)
      } catch (e) {
        console.log(e);
        toast.error('Não foi possível indicar a cidade. Tente novamente mais tarde', configToast)
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.warning('Necessário preencher todos os campos', configToast)
    }
  }

  const handleClearFilter = () => {
    setSelectedRegion('')
    setCityOpen('')
  }

  const handleSelectRegion = (region) => {
    setCityOpen('')
    setSelectedRegion(region)
  }

  if (!currentUser) {
    return (
      <Container>
        <Titulo>Usuário não identificado</Titulo>
        <Subtitulo>É necessário se autenticar no app para efetuar a inscrição.</Subtitulo>
      </Container>
    )
  }

  return (
    <Container>
      <img src='/imgs/mapa-plug/logo.svg' />
      <Titulo>{showIndicateCity ? 'Indique uma cidade' : 'Selecione a região'}</Titulo>
      {showIndicateCity ? (
        <>
          <Subtitulo>
            Não encontrou sua cidade em nossa lista ou tem informações interessantes sobre ela?
            <br />
            Conte para nós e ajude-nos a compartilhar sua história!
          </Subtitulo>
          <Formulario onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Nome da cidade..."
              name="cidade"
              value={form.cidade}
              onChange={handleChangeForm}
            />
            <textarea
              placeholder="Escreva em poucas palavras sobre a cidade..."
              rows="5"
              name="descricao"
              value={form.descricao}
              onChange={handleChangeForm}
            />
            <div>
              <button onClick={() => setShowIndicateCity(false)}>voltar</button>
              <button type='submit'>{isLoading ? 'enviando...' : 'enviar'}</button>
            </div>
          </Formulario>
        </>
      ) : (
        <>
          <Mapa>
            <MapaSvg selectedRegion={selectedRegion} setSelectedRegion={handleSelectRegion} />
            <Pin src='/imgs/mapa-plug/pin.png' />
          </Mapa>
          <Botoes>
            <ConfiraNoMapa href='https://www.google.com/maps/d/u/0/viewer?mid=1yYQ8LjEt_svzXTQJVn3msQhZpsOqLQw&ll=-24.481732190553476%2C-48.71366769121697&z=6'>confira no mapa</ConfiraNoMapa>
            <IndiqueUmaCidade onClick={() => setShowIndicateCity(true)}>indique uma cidade</IndiqueUmaCidade>
          </Botoes>
          <Filtros>
            {selectedRegion && (
              <Regiao>
                {regions[selectedRegion]}
                <button onClick={handleClearFilter}>X</button>
              </Regiao>
            )}
            <Pesquisar hasRegion={!!selectedRegion} placeholder="Pesquise a cidade" onChange={(e) => setSearch(e.target.value)} />
          </Filtros>
          <Cidades>
            {cities
              .filter(city => !selectedRegion || city.region === selectedRegion)
              .filter(city => city.name.toLowerCase().includes(search.toLowerCase()))
              .map((city, key) => (
                <div key={key}>
                  <Cidade ativo={cityOpen === key} onClick={() => setCityOpen(cityOpen === key ? null : key)}>
                    <h2>{city.name}</h2>
                    <BotaoExpandir ativo={cityOpen === key}>
                      <img src='/imgs/mapa-plug/arrow.svg' alt="Expandir" />
                    </BotaoExpandir>
                  </Cidade>
                  {cityOpen === key && (
                    <ConteudoExpandido>
                      <UltimaExibicao>
                        Última exibição: <span>{city.last_exhibition}</span>
                      </UltimaExibicao>
                      <BotoesConteudoExpandido>
                        {!!city.link && <ConfiraMateria target="_blank" href={city.link}>confira a matéria</ConfiraMateria>}
                        <AssistirPrograma href={city.globoplay}>assistir o programa</AssistirPrograma>
                      </BotoesConteudoExpandido>
                    </ConteudoExpandido>
                  )}
                </div>
              ))
            }
          </Cidades>
        </>
      )}
    </Container>
  );
};

export default MapaPlug;
